import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Navbar from "./Navbar";
import { setOpenDrawer } from "../store/generalSlice";

const TermsAndConditions = () => {
  const dispatch = useDispatch();

  const { openDrawer } = useSelector((state) => state.generalSlice);
  function openAndCloseDrawer() {
    dispatch(setOpenDrawer(!openDrawer));
  }

  return (
    <Grid
      container
      item
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        mx: "auto",

        background: "linear-gradient(288deg, #FCEDDE 5.46%, #FFE3C7 48.06%, #FFFCFA 101.46%)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "auto",
          alignItems: "flex-start",
          maxWidth: "1200px",
          mx: "auto",
          width: "100%",
          pb: { xs: "30px", md: "120px" },
          px: { xs: "30px", sm: "60px" },
        }}
      >
        <Navbar color="#000" open={openDrawer} setOpen={() => openAndCloseDrawer()} />
        <Grid
          container
          item
          xs={12}
          direction="column"
          justifyContent="center"
          sx={{
            pb: { xs: "30px", md: "120px" },

            pt: { xs: "30px", sm: "60px" },
          }}
        >
          <Typography
            component="h3"
            sx={{
              fontSize: { xs: "30px", md: "41px" },
              lineHeight: "42px",
              mb: "30px",
              fontWeight: 600,
              textAlign: "center",
              fontFamily: "Raleway",
            }}
          >
            Terms and Conditions:
          </Typography>
          <Typography
            component="h3"
            sx={{
              fontSize: { xs: "14px", md: "18px" },
              lineHeight: "25px",
              mb: "30px",
              fontWeight: 600,

              fontFamily: "Raleway",
              textAlign: "justify",
              whiteSpace: "pre-line",
            }}
          >
            Please read these terms and conditions carefully before using the Cargosmd Website. The content of the Site
            is provided for information purposes only. {"\n"}By using the Site, users will be bound by these terms and
            conditions and furthermore agree to abide by them. These terms and conditions are applicable to all Sites
            within the Cargosmd group of companies (i.e. Cargos Software).
          </Typography>

          <Typography
            component="h3"
            sx={{
              fontSize: { xs: "14px", md: "18px" },
              lineHeight: "25px",
              mb: "30px",
              fontWeight: 600,

              fontFamily: "Raleway",
              textAlign: "justify",
              whiteSpace: "pre-line",
            }}
          >
            1. The content of the Site is protected by copyright laws and may not be copied or distributed without the
            prior written consent of Cargos Software. {"\n"}2. Content provided through the Site and newsletters do not
            give warranties of any kind. Misprints, errors, or mistakes are possible. In such instances, Cargos Software
            reserves the right to correct such occurrences without prior notice and without accepting liability flowing
            from the same. {"\n"}3. If users do not agree to the terms and conditions they are not permitted to use the
            Site, or any information contained therein. Such users must exit the Site(s) immediately.{"\n"} 4.
            Newsletters and correspondence will be sent to the email address or other address provided. Recipients may
            unsubscribe for newsletters at any time.{"\n"} 5. Amendments to content on Site(s) and newsletters are
            possible and may occur without prior notice. Notwithstanding the afore-going, Cargos Software does not make
            any commitment to update such content. {"\n"}6. Cargos Software accepts no liability for the accuracy and/or
            completeness of the information provided unless same is as a result of gross negligence or willful
            misconduct on the part of Cargos Software. {"\n"}7. Cargos Software records that the website may from time
            to time be subject to routine enhancements, maintenance and upgrades and accepts no liability for any error
            and/or miscommunication that may occur at such time. {"\n"}8. In instances where access location is required
            to operate, users agree to Cargos Software and its agents having access to their location and give consent
            for their location to be used for research, analysis, and statistical purposes. Users accept to comply with
            the terms and conditions that are applicable to any offers made by Cargos Software or any third parties.{" "}
            {"\n"}9. Users accept the sole responsibility to protect their usernames and passwords and agree to not
            permit other persons to utilize their access permissions. The user is solely responsible for activities
            conducted through the site. {"\n"}10. The terms and conditions, and cancellation policy of the respective
            User will apply. {"\n"}11. Cargos Software reserves the right to amend these terms and conditions or to
            terminate promotional offers without prior notice. {"\n"}12. These terms, conditions, Privacy Notice and all
            transactions concluded on the Sites are governed by Republic of Moldova law. By using the Site users consent
            to the jurisdiction of the Republic of Moldova courts to resolve disputes which may arise from using the
            Site or bookings made at Cargos Software. {"\n"}13. Users accept that Cargos Software takes reasonable and
            practical steps to safeguard Protected Personal Information in its possession. To the fullest extent
            permissible by law, users hereby indemnify and holds harmless Cargos Software, its employees and agents
            against any liability, damages and/or legal action whatsoever and howsoever arising, including negligence on
            the part of Cargos Software, its employees or agents and Cargos Software shall not accept liability for any
            damages or any type of loss suffered by Cargos Software customers when utilizing the services of the Site
            and Third Party Sites. {"\n"}14. These terms and conditions are severable from each other. Should any term
            or condition be found unenforceable in law whether in part or whole, then such term or condition or part
            thereof will be severed from these terms and conditions and the remaining terms and conditions or portions
            thereof will remain in force. {"\n"}15. No indulgence or waiver of these terms and conditions by Bradford
            Jacobs will constitute any waiver of the rights of Cargos Software.
          </Typography>
        </Grid>
      </Box>
    </Grid>
  );
};

export default TermsAndConditions;
