import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import blackLogo from "../images/Black_Orange_Logo.svg";
import message from "../images/icon_message.svg";
import phone from "../images/icon_phone.svg";
import skype from "../images/icon_skype.svg";
import { NavLink } from "react-router-dom";
import SocialMedia from "./SocialMedia";

const footerColumns = [
  {
    id: 9001,
    title: "Contact us",
    list: ["+373 22 999 470", "+44 20 7097 4622", "go@cargosmd.com", "cargos.md"],
  },
  {
    id: 9002,
    title: "Company",
    list: ["Overview", "Features", "Services", "Our projects"],
    linkto: ["/#overview", "/#features", "/#services", "/projects"],
  },
  {
    id: 9003,
    title: "Help",
    list: ["Request a Quote", "Support", "Terms and conditions"],
    linkto: ["#overview", "#features", "#services"],
  },
];

const Footer = ({ color }) => {
  const footerBg = color;

  return (
    <Grid
      container
      item
      sx={{
        display: "flex",
        position: "relative",
        flexDirection: "column",
        mx: "auto",
        pt: "30px",
        backgroundColor: footerBg,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          mx: "auto",
          maxWidth: "1200px",
          px: { xs: "30px", sm: "60px" },
          pt: { xs: "0", md: "14px" },
          pb: { xs: "30px", md: "14px" },
        }}
      >
        <Grid container item xs={12} spacing={3}>
          {/* CARGOS MD */}
          <Grid item md={3} sx={{ display: { xs: "none", md: "flex" }, pr: "30px" }}>
            <Box
              sx={{
                flexDirection: "column",
                width: "auto",
              }}
            >
              <NavLink to="/">
                <img src={blackLogo} alt="cargos software dark logo" />
              </NavLink>
              <Typography
                sx={{
                  pt: "20px",
                  fontFamily: "Raleway",
                  color: "rgba(0, 0, 0, 0.80)",
                  textAlign: "left",
                  fontSize: "14px",
                  lineHeight: "20px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  maxWidth: "240px",
                }}
              >
                Cargos Software is a leading software and IT company, providing an extensive range of services for
                Transport Management Industry.
              </Typography>
            </Box>
          </Grid>

          {footerColumns.map((column, index) => (
            <Grid item key={column.id} xs={12} sm={3}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Raleway",
                    color: "rgba(0, 0, 0, 0.70)",
                    textAlign: "justify",
                    fontSize: "18px",
                    lineHeight: "24px",
                    fontStyle: "normal",
                    fontWeight: 700,
                  }}
                >
                  {column.title}
                </Typography>
              </Box>

              {column.list.map((row, i) => (
                <List key={row} sx={{ p: 0 }} disablePadding>
                  <ListItem disablePadding>
                    <ListItemButton
                      disableRipple
                      sx={{
                        px: 0,
                        overflow: "hidden",
                        "&:hover": {
                          backgroundColor: "transparent",
                          opacity: 0.5,
                        },
                      }}
                      onClick={(e) => {
                        if (index === 0 && i === 0) {
                          window.open("tel:" + row);
                          e.preventDefault();
                        }
                        if (index === 0 && i === 1) {
                          window.open("tel:" + row);
                          e.preventDefault();
                        }
                        if (index === 0 && i === 2) {
                          window.location.href = "skype:live:3f419801fb1771ae";
                          e.preventDefault();
                        }
                        if (index === 2 && i === 0) {
                          window.location.href = "mailto:support@cargosmd.com";
                          e.preventDefault();
                        }
                        if (index === 2 && i === 1) {
                          window.open("tel:+37322999470");
                          e.preventDefault();
                        }
                      }}
                      component={index === 1 || (index === 2 && i === 2) ? NavLink : ""}
                      to={index === 1 ? column.linkto[i] : index === 2 && i === 2 ? "/terms_and_conditions" : ""}
                    >
                      <ListItemIcon
                        sx={{
                          display: column.title === "Contact us" ? "flex" : "none",
                          minWidth: "auto",
                          mr: "20px",
                        }}
                      >
                        <img src={i < 2 ? phone : i === 2 ? message : skype} alt="" />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{
                          fontFamily: "Raleway",
                          fontSize: "14px",
                          color: "rgba(0, 0, 0, 0.70)",
                          fontWeight: 400,
                          lineHeight: "20px",
                        }}
                        primary={row + (i === 0 ? " (MD) Head" : i === 1 ? " (UK) Sales" : "")}
                        sx={{ m: 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                </List>
              ))}
              {index === 0 && <SocialMedia sx={{ pt: "10px" }} />}
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Copyright */}
      <Box
        sx={{
          maxWidth: "1200px",
          width: "100%",
          mx: "auto",

          px: { xs: "30px", sm: "60px", md: "60px" },
          pt: { xs: "0", sm: "20px", md: "120px" },
          pb: { xs: "10px", md: "14px" },
          backgroundColor: color,
        }}
      >
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "20px",
            color: "rgba(0, 0, 0, 0.50)",
          }}
          align="left"
        >
          {"© Copyright Cargos Software"} {new Date().getFullYear()}
        </Typography>
      </Box>
    </Grid>
  );
};

export default Footer;
