import ce_logo from "../images/ce_logo.svg";
import cdp from "../images/cdp.svg";
import tms from "../images/tms.svg";
import cargoexchange from "../images/cargoexchange.webp";
import cargostms from "../images/cargostms.webp";
import contdeplata from "../images/contdeplata.webp";
import cargostms_main from "../images/mockup_bg.webp";
import ce_main_image from "../images/ce_main_image.webp";
import contdeplata_main_image from "../images/contdeplata_main_image.webp";
import bt_info from "../images/b_t_info.webp";
import bt_info_logo from "../images/bt_info_logo.webp";
import bt_info_main from "../images/bt_info_main.webp";
import aad_logo from "../images/artaddict_logo.svg";
import aad_showcase from "../images/artaddict-showcase.webp";
import aad_page from "../images/artaddict-design.webp";

export const projectList = [
  {
    id: 1111,
    title: "Transport Management System",
    logo: tms,
    description:
      "TMS simplifies the process of planning and executing shipments. It offers detailed monitoring and control of loads. This software is 100% online and assist collaboration between company members. This  allow companies to increase efficiency and optimize the time spent on carrying out the tasks of carriers, shippers, and dispatchers.",
    url: "https://www.cargos.md",
    approches: ["Website Design", "SaaS Platform", "Web Developement"],
    image: cargostms,
    linkto: "project/#cargos_tms",
    main_image: cargostms_main,
  },
  {
    id: 2222,
    title: "Freight Exchange Marketplace",
    logo: ce_logo,
    description:
      "This is a digital marketplace featuring a freight exchange which supports trade and industry companies, freight forwarders and road hauliers to assign and find transport orders for road transport.",
    url: "https://www.cargo.md",
    approches: ["Website Design", "SaaS Platform", "Web Developement"],
    image: cargoexchange,
    linkto: "project/#freight_exchange",
    main_image: ce_main_image,
  },
  {
    id: 3333,
    title: "Online Invoicing",
    logo: cdp,
    description:
      "This is an application that helps you create online invoices and send them directly to the client by email.",
    url: "https://www.contdeplata.md",
    approches: ["Website Design", "SaaS Platform", "Web Developement"],
    image: contdeplata,
    linkto: "project/#contdeplata",
    main_image: contdeplata_main_image,
  },
  {
    id: 4444,
    title: "Transport News Portal",
    logo: bt_info_logo,
    description:
      "CargoExchange Info provides information that empower good decision making when it comes to Transport and Logistics industry.   ",
    url: "https://www.bursatransport.info",
    approches: ["Website Design", "Web Developement", "Content Creation"],
    image: bt_info,
    linkto: "project/#bursatransportinfo",
    main_image: bt_info_main,
  },
  {
    id: 5555,
    title: "Web Design & Development",
    logo: aad_logo,
    description:
      "ArtAddict Design is a creative hub dedicated to helping companies establish a strong online presence. Specializing in website design & build, and blog article writing, ArtAddict Design caters to small and medium-sized businesses seeking to enhance their digital footprint.",
    url: "https://www.artaddictdesign.com",
    approches: ["Website Design", "Web Developement", "SMM"],
    image: aad_showcase,
    linkto: "project/#artaddict",
    main_image: aad_page,
  },
];
