import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { useTheme, useMediaQuery } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import HeaderDrawerList from "./HeaderDrawerList";
import { setOpenDrawer } from "../store/generalSlice";
import { companyLogoWhite } from "../data/imageURL";

const drawerWidth = 300;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("md")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  [theme.breakpoints.down("md")]: {
    width: 0,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
  padding: theme.spacing(0, 1),
  hight: "auto",
  ////// necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function Header({ children }) {
  const dispatch = useDispatch();
  const theme = useTheme();

  const mdMediaDown = useMediaQuery(theme.breakpoints.down("md"));

  const { openDrawer } = useSelector((state) => state.generalSlice);
  const handleDrawerClose = () => dispatch(setOpenDrawer(false));

  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: theme.palette.backgroundColor.light,
        height: "auto",
        width: "auto",
        padding: 0,
      }}
    >
      {mdMediaDown && (
        <Drawer
          variant={mdMediaDown ? undefined : "permanent"}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: "nowrap",
            boxSizing: "border-box",
            ...(openDrawer && {
              ...openedMixin(theme),
              "& .MuiDrawer-paper": openedMixin(theme),
              "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
                backgroundColor: "#FFF",
              },
              "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
                borderRadius: 0,
                backgroundColor: "#023047",
                minHeight: 24,
                border: "2px solid #364958",
                opacity: 0.5,
              },

              "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
                backgroundColor: "#364958",
                border: "2px solid #364958",
              },
            }),
            ...(!openDrawer && {
              ...closedMixin(theme),
              "& .MuiDrawer-paper": closedMixin(theme),
              "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
                display: "none",
              },
            }),
          }}
          PaperProps={{
            style: {
              backgroundColor: "#1a1a1a",
              color: "white",
              height: "100%",
              paddingBottom: "30px",
            },
          }}
          anchor="right"
          open={openDrawer}
          onClose={handleDrawerClose}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose} color="inherit" sx={{ pl: "20px" }}>
              <img src={companyLogoWhite} alt="" style={{ paddingLeft: "12px" }} />
            </IconButton>
          </DrawerHeader>

          <Divider />

          <HeaderDrawerList open={openDrawer} handleDrawerClose={handleDrawerClose} />
        </Drawer>
      )}

      <Box
        component="main"
        sx={{
          width: "100vw",
          mx: "auto",
        }}
        open={openDrawer}
      >
        <Container disableGutters={true} maxWidth={false}>
          <Grid container>{children}</Grid>
        </Container>
      </Box>
    </Box>
  );
}
