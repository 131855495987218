import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import Navbar from "./Navbar";

import Footer from "./Footer";
import { useDispatch, useSelector } from "react-redux";
import { setOpenDrawer } from "../store/generalSlice";
import { projectList } from "../data/generalData";
import ActionButtons from "./ActionButtons";

const Projects = ({ id }) => {
  const dispatch = useDispatch();

  const { openDrawer } = useSelector((state) => state.generalSlice);
  function openAndCloseDrawer() {
    dispatch(setOpenDrawer(!openDrawer));
  }

  return (
    <Grid
      id={id}
      container
      item
      sx={{
        position: "relative",
        display: "flex",
        mx: "auto",
        height: "auto",
      }}
    >
      {/* Header section */}
      <Grid container item sx={{ backgroundColor: "#FDE8D2" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "auto",
            alignItems: "flex-start",
            maxWidth: "1200px",
            mx: "auto",
            width: "100%",
            pb: { xs: "30px", md: "120px" },
            px: { xs: "30px", sm: "60px" },
          }}
        >
          <Navbar
            open={openDrawer}
            setOpen={() => openAndCloseDrawer()}
            color="#000"
          />

          <Typography
            component="h4"
            sx={{
              pt: { xs: "30px", sm: "60px", md: "127px" },
              fontFamily: "Raleway",
              textTransform: "uppercase",
              color: "rgba(0, 0, 0)",
              textAlign: "center",
              fontSize: { xs: "30px", md: "41px" },
              fontStyle: "normal",
              fontWeight: 600,
              width: "100%",
              lineHeight: { xs: "35px", md: "48px" },
            }}
          >
            CARGOS<span style={{ color: "#F48400" }}> Software</span> PROJECTS
          </Typography>
          <Typography
            component="h4"
            sx={{
              pt: "37px",
              fontFamily: "Raleway",
              color: "rgba(0, 0, 0)",
              textAlign: "center",
              fontSize: { xs: "16px", md: "21px" },
              lineHeight: { xs: "25px", md: "29px" },
              fontStyle: "normal",
              fontWeight: 400,
              width: "100%",
              maxWidth: "550px",
              mx: "auto",
            }}
          >
            Here are some examples of our software and services solutions for
            transport, logistics, and invoicing.
          </Typography>
        </Box>
      </Grid>
      {/* image */}
      <Grid
        container
        item
        sx={{
          position: "relative",
          height: "content",
          pt: { xs: "0px", sm: "60px", md: "90px" },
          pb: { xs: "30px", md: "120px" },
          backgroundSize: "contain",
          backgroundPosition: "50% 50%",
          backgroundRepeat: "no-repeat",
          maxWidth: "1600px",
          mx: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            maxWidth: "1200px",
            mx: "auto",
            width: "100%",

            // pb: { xs: "30px", md: "90px" },
            // mb: { xs: "-150px", md: "-60px" },
            px: { xs: "30px", sm: "60px" },
          }}
        >
          <Grid container item sx={{ display: "flex", height: "auto" }}>
            {projectList.map((project) => (
              <Grid
                container
                item
                key={project.id}
                sx={{ py: { xs: "60px", md: "80px" }, px: 0 }}
              >
                <Grid item xs={12} md={6}>
                  <Typography
                    component="h4"
                    sx={{
                      fontFamily: "Raleway",
                      textTransform: "uppercase",
                      fontSize: "14px",
                      fontWeight: "700",
                      letterSpacing: "2.8px",
                      color: "#B7B4B9",
                      lineHeight: "110%",
                    }}
                  >
                    latest project
                  </Typography>
                  <Typography
                    component="h3"
                    sx={{
                      fontFamily: "Raleway",
                      color: "#383838",
                      textAlign: "left",
                      fontSize: { xs: "30px", md: "35px" },
                      fontStyle: "normal",
                      fontWeight: 600,
                      width: "100%",
                      lineHeight: { xs: "35px", md: "48px" },
                    }}
                  >
                    {project.title}
                  </Typography>
                  <Box
                    sx={{
                      // display: "flex",
                      width: "auto",
                      maxWidth: "260px",
                      height: "60px",
                      // bgcolor: "#c5c5c5",
                      overflow: "hidden",
                      boxSizing: "border-box",
                      my: "20px",
                      border: "2px solid #383838",
                    }}
                  >
                    <img
                      src={project.logo}
                      alt={project.title}
                      style={{
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                        padding: "10px",
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      mt: "30px",
                    }}
                  >
                    {project.approches.map((approche, index) => (
                      <Box sx={{ bgcolor: "#F9EFE7", mr: "20px" }} key={index}>
                        <Typography
                          component="h5"
                          sx={{
                            mx: "10px",
                            fontWeight: 700,
                            fontSize: { xs: "12px", md: "16px" },
                          }}
                        >
                          {approche}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                  <Typography
                    component="h5"
                    sx={{
                      pt: "30px",
                      fontFamily: "Raleway",
                      color: "rgba(0, 0, 0)",
                      textAlign: "left",
                      fontSize: { xs: "16px", md: "18px" },
                      lineHeight: { xs: "25px", md: "32px" },
                      fontStyle: "normal",
                      fontWeight: 400,
                      width: "100%",
                      mx: "auto",
                    }}
                  >
                    {project.description}
                  </Typography>
                  {/* Buttons */}
                  <Box sx={{ display: { xs: "none", md: "flex" } }}>
                    <ActionButtons project={project} />
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    boxSizing: "border-box",
                    alignSelf: "center",

                    pt: { xs: "50px", md: 0 },
                    pl: { xs: 0, md: "50px" },
                  }}
                >
                  <img
                    src={project.image}
                    alt={project.description}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "contain",
                    }}
                  />
                  <Box sx={{ display: { xs: "flex", md: "none" }, pt: "30px" }}>
                    <ActionButtons project={project} />
                  </Box>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Grid>
      {/* Footer */}
      <Grid container item>
        <Footer color="#FDE8D2" />
      </Grid>

      <Box
        sx={{
          display: "flex",
          position: "absolute",
          width: "auto",
          height: "auto",
          maxWidth: "600px",
          maxHeight: "500px",
          zIndex: 0,
          top: "35%",
          left: "-5%",
        }}
      ></Box>
    </Grid>
  );
};

export default Projects;
