import { Link, NavLink } from "react-router-dom";
import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

const ActionButtons = ({ project }) => {
  const ButtonVisit = styled(Button)(() => ({
    textTransform: "none",
    color: "#000",
    fontFamily: "Raleway",
    textAlign: "center",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 700,
    width: "auto",
    height: "auto",
    mx: "auto",
    "&:hover": {
      backgroundColor: "transparent",
      opacity: 0.7,
    },
  }));
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        width: "100%",
        justifyContent: "space-between",
        pt: { xs: "30px", md: "60px" },
      }}
    >
      <div>
        <NavLink to={project.linkto}>
          <button className="primary-button" component={Link}>
            <span className="primary-button__text">see this project</span>
          </button>
        </NavLink>
      </div>

      <ButtonVisit
        sx={{ lineHeight: { xs: "20px", md: "25px" } }}
        disableRipple
        component={Link}
        endIcon={<ArrowRightAltIcon />}
        onClick={(e) => {
          window.open(project.url);
          e.preventDefault();
        }}
      >
        visit website
      </ButtonVisit>
    </Box>
  );
};

export default ActionButtons;
