import React from "react";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import configureStore from "../src/store/store";
import { HelmetProvider } from "react-helmet-async";
import { createRoot } from "react-dom/client";

const APP = (
  <HelmetProvider>
    <Provider store={configureStore}>
      <App />
    </Provider>
  </HelmetProvider>
);

const container = document.getElementById("root");
const root = createRoot(container);
root.render(APP);

reportWebVitals();
