import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import office_bg from "../images/section2_gb.webp";
import optimizationIcon from "../images/feature_optimization.svg";
import goDigitalIcon from "../images/feature_go_digital.svg";
import supportIcon from "../images/feature_24_7.svg";

const Features = ({ id }) => {
  const features = [
    {
      id: 11,
      title: "OPTIMIZATION & MANAGEMENT",
      description:
        "We’ll  help you grow with optimizing your inventory levels to reduce costs and improve efficiency, while ensuring that you always have the right services on hand to meet customer demand.",
      icon: optimizationIcon,
    },
    {
      id: 12,
      title: "GO DIGITAL",
      description:
        "Our adoption and use of digital technologies in logistics processes such as AI and cloud computing will help you improve your operational efficiency, reduce costs, and increase transparency.",
      icon: goDigitalIcon,
    },
    {
      id: 13,
      title: "CUSTOMER \n SUPPORT",
      description:
        "Our specialized team provides expert guidance, resolving queries promptly to optimize operations. Offering personalized support, we streamline logistical challenges for seamless client experiences.",
      icon: supportIcon,
    },
  ];

  return (
    <Grid
      id={id}
      container
      item
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        mx: "auto",
        backgroundImage: `url(${office_bg})`,
        backgroundSize: "cover",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: "1200px",
          mx: "auto",
          pt: { xs: "30px", sm: "60px", md: "90px" },
          pb: { xs: "30px", sm: "60px", md: "140px" },
          px: { xs: "30px", sm: "60px", md: "60px" },
        }}
      >
        <Typography
          sx={{
            width: { xs: "fit-content", md: "50%" },
            fontFamily: "Raleway",
            fontSize: { xs: "26px", sm: "30px", md: "41px" },
            lineHeight: { xs: "28px", sm: "35px", md: "normal" },
            mt: { xs: 0, sm: "20px", md: "20px" },
            mb: { xs: 0, sm: "10px", md: "20px" },
            fontStyle: "normal",
            fontWeight: 600,
            height: "auto",
            pt: { xs: 0, md: "100px" },
            color: "#000",
            textAlign: "left",

            background: "linear-gradient(90deg, #FFF 0%, #F48400 100%)",
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          CARGOS SOFTWARE
        </Typography>
        <Typography
          sx={{
            pt: "20px",
            width: { xs: "auto", md: "530px" },
            fontFamily: "Raleway",
            fontSize: { xs: "13px", sm: "18px", md: "20px" },
            lineHeight: { xs: "20px", sm: "25px", md: "28px" },
            fontStyle: "normal",
            fontWeight: 400,
            height: { xs: "auto", md: "110px" },
            color: "#FFF",
            maxWidth: { xs: "350px", md: "510px" },
            mb: { xs: "30px", sm: "100px", md: "140px" },
          }}
        >
          Our logistics solutions are tailored to meet the unique needs of your
          business, whether you're a small start-up or a large enterprise.
        </Typography>
        <Grid container item xs={12}>
          {features.map((feature) => (
            <Grid
              key={feature.id}
              item
              sm={4}
              sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                height: "auto",
                mb: { xs: "10px", sm: "0" },
              }}
            >
              <img
                src={feature.icon}
                alt={feature.title}
                style={{
                  display: "flex",
                  width: "auto",
                  height: "40px",
                  alignSelf: "flex-start",
                }}
              />
              <Typography
                component="h3"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  maxWidth: { sm: "150px", md: "300px" },
                  my: { xs: "5px", sm: "20px" },
                  ml: { xs: 0, sm: "50px" },
                  color: "#FFF",
                  textAlign: "left",
                  fontFamily: "Oswald",
                  fontSize: { xs: "16px", sm: "20px", md: "28px" },
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: { xs: "22px", sm: "30px", md: "35px" },
                  maxHeight: "70px",
                  height: "100%",
                }}
              >
                {feature.title}
              </Typography>
              <Typography
                component="h4"
                sx={{
                  mt: { xs: "10px", sm: "20px" },
                  ml: { xs: "0", sm: "50px" },

                  color: "#FFF",
                  textAlign: "left",
                  fontFamily: "Raleway",
                  fontSize: { xs: "13px", sm: "16px", md: "20px" },
                  fontStyle: "normal",
                  fontWeight: 300,
                  lineHeight: { xs: "20px", sm: "26px", md: "30px" },
                  height: "100%",
                }}
              >
                {feature.description}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Grid>
  );
};

export default Features;
