import { useState } from "react";
import { NavLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useTheme, useMediaQuery } from "@mui/material";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { motion } from "framer-motion";

const ListItemButtonStyled = styled(ListItemButton)(() => ({
  marginRight: 4,
  marginLeft: 4,
  borderRadius: 2,
  textTransform: "none",

  "&.MuiListItemButton-root": {
    paddingTop: 12,
    paddingBottom: 12,
  },
  "& svg": {
    color: "rgba(255,255,255,0.8)",
    transition: "0.2s",
    transform: "translateX(0) rotate(0)",
  },
  "&:hover, &:focus": {
    bgcolor: "unset",
    "& svg:first-of-type": {
      transform: "translateX(-4px) rotate(-20deg)",
    },
    "& svg:last-of-type": {
      right: 0,
      opacity: 1,
    },
  },
  "&.Mui-selected": {
    color: "white",
    "& svg:first-of-type": {
      color: "white",
    },
  },
  "&:after": {
    content: '""',
    position: "absolute",
    height: "80%",
    display: "block",
    left: 0,
    width: "1px",
    bgcolor: "divider",
  },
}));

const ListItemTextStyled = styled(ListItemText)(() => ({
  ".MuiListItemText-primary": {
    fontWeight: "500",
    fontFamily: "Raleway",
    fontSize: 13,
    letterSpacing: 0.65,
    opacity: 0.7,
    lineHeight: "normal",
    "&:hover": {
      opacity: 1,
    },
  },
}));

const HeaderDrawerList = (props) => {
  const { handleDrawerClose } = props;
  const theme = useTheme();
  const mdMediaDown = useMediaQuery(theme.breakpoints.down("md"));
  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleListItemClick = (_, index) => {
    setSelectedIndex(index);
  };

  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 1,
        staggerChildren: 0.2,
      },
    },
  };

  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      delayChildren: 1,
    },
  };
  return (
    <>
      {mdMediaDown && (
        <List
          disablePadding
          sx={{ pl: "20px" }}
          variants={container}
          component={motion.div}
          initial="hidden"
          animate="visible"
        >
          <ListItemButtonStyled
            disableRipple
            onClick={(event) => [handleDrawerClose()]}
            component={NavLink}
            to="/#features"
            sx={{ py: 2 }}
          >
            <ListItemTextStyled
              primary={"Features"}
              primaryTypographyProps={{ fontWeight: "800" }}
              className="item"
              component={motion.span}
              variants={item}
            />
          </ListItemButtonStyled>

          <ListItemButtonStyled
            disableRipple
            onClick={(event) => [handleDrawerClose()]}
            component={NavLink}
            to="/#services"
            sx={{ py: 2 }}
          >
            <ListItemTextStyled primary={"Services"} className="item" component={motion.span} variants={item} />
          </ListItemButtonStyled>
          <ListItemButtonStyled
            disableRipple
            selected={selectedIndex === 20}
            onClick={(event) => [handleListItemClick(event, 20), handleDrawerClose()]}
            component={NavLink}
            to="/#contact_us"
            sx={{ py: 2 }}
          >
            <ListItemTextStyled primary={"Contact us"} className="item" component={motion.span} variants={item} />
          </ListItemButtonStyled>

          <ListItemButtonStyled
            disableRipple
            onClick={(event) => [handleDrawerClose()]}
            component={NavLink}
            to="/projects"
            sx={{ py: 2 }}
          >
            <ListItemTextStyled primary={"Our projects"} className="item" component={motion.span} variants={item} />
          </ListItemButtonStyled>

          <ListItemButtonStyled
            disableRipple
            selected={selectedIndex === 20}
            onClick={(event) => {
              window.location.href = "mailto:support@cargosmd.com";
              event.preventDefault();
              handleDrawerClose();
            }}
            sx={{
              width: "156px",
              height: "40px",
              borderRadius: "41px",
              background: "rgba(255, 255, 255, 0.15)",
              color: "#fff",
              fontFamily: "Raleway",
              opacity: 0.7,
              fontSize: "13px",
              textTransform: "none",
              textAlign: "center",
              mt: "20px",

              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.15)",
                color: "#fff",
                opacity: 1,
              },
            }}
          >
            <ListItemTextStyled primary={"Request a Quote"} className="item" component={motion.span} variants={item} />
          </ListItemButtonStyled>
        </List>
      )}
    </>
  );
};

export default HeaderDrawerList;
