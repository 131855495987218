import { NavLink, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MuiAppBar from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import { companyLogoWhite } from "../data/imageURL";
import { companyLogoBlack } from "../data/imageURL";
import { useTheme } from "@mui/material/styles";
import { motion } from "framer-motion";

const drawerWidth = 265;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginRight: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const ButtonStyled = styled(Button)(({ theme }) => ({
  marginRight: 4,
  marginLeft: 4,
  borderRadius: 2,
  textTransform: "none",
  fontFamily: "Raleway",
  fontSize: 13,
  fontWeight: 500,
  letterSpacing: "0.65px",
  lineHeight: "normal",
  opacity: 0.7,
  "&:hover": {
    backgroundColor: "transparent",
    borderColor: "#f76811",
    opacity: 1,
  },
}));
const ButtonStyledLogIn = styled(Button)(() => ({
  width: "156px",
  height: "40px",
  borderRadius: "41px",
  background: "rgba(255, 255, 255, 0.05)",
  color: "rgba(255, 255, 255, 0.05)",
  fontFamily: "Raleway",
  opacity: 0.7,
  fontSize: "13px",
  textTransform: "none",

  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    color: "#fff",
    opacity: 1,
  },
}));

const Navbar = (props) => {
  const { open, setOpen, color } = props;

  const navbarFontColor = color;
  const theme = useTheme();
  const mdMediaDown = useMediaQuery(theme.breakpoints.down("md"));
  const smMediaDown = useMediaQuery(theme.breakpoints.down("sm"));
  const mdLgMediaMax = useMediaQuery("(max-width:900px)");

  const handleDrawerOpen = () => setOpen(true);

  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: location.pathname === "/" ? 3 : 0,
        staggerChildren: 0.2,
      },
    },
  };

  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };
  const toolbar = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delay: location.pathname === "/" ? 2 : 0,
        duration: location.pathname === "/" ? 2 : 1,
      },
    },
  };

  return (
    <AppBar
      position="static"
      open={open}
      elevation={0}
      style={{
        backgroundColor: "transparent",
      }}
    >
      <Toolbar
        disableGutters={true}
        sx={{
          bgcolor: "transparent",
          height: "40px",
          pt: "10px",
          width: "100%",
          maxWidth: "1250px",
          mx: "auto",
        }}
      >
        {mdLgMediaMax && (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
            component={motion.div}
            variants={toolbar}
            initial="hidden"
            animate="visible"
          >
            <Button
              disableRipple
              component={NavLink}
              to="/"
              sx={{
                px: 0,
                height: 60,
                justifyContent: "start",
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
            >
              <img
                src={color === "#000" ? companyLogoBlack : companyLogoWhite}
                alt="Cargos Logo"
                style={{
                  width: "auto",
                  maxWidth: open ? 80 : 100,
                  height: "auto",
                }}
              />
            </Button>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              sx={{
                mr: mdMediaDown ? 0.5 : 4,
                ...(open && { display: "none" }),
                color: color,
                "&:hover": {
                  backgroundColor: "transparent",
                  opacity: 0.6,
                },
              }}
            >
              <MenuIcon />
            </IconButton>
          </Box>
        )}
        {!mdLgMediaMax && (
          <>
            <Grid container item xs={12}>
              <Stack
                direction="row"
                flexWrap="wrap"
                variants={container}
                component={motion.div}
                initial="hidden"
                animate="visible"
                sx={{
                  display: "flex",
                  flexGrow: 1,
                  justifyContent: "space-between",
                  maxWidth: "1200px",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <motion.div className="item" variants={item}>
                  <Button component={NavLink} to="/" disableRipple>
                    <img
                      src={color === "#000" ? companyLogoBlack : companyLogoWhite}
                      alt="Cargos Logo"
                      style={{
                        width: smMediaDown ? "50%" : "100%",
                        height: "auto",
                      }}
                      variants={item}
                    />
                  </Button>
                </motion.div>
                <ButtonStyled disableRipple variant="text" component={NavLink} to="/#features" size="large">
                  <Typography
                    color={navbarFontColor}
                    variant="text"
                    className="item"
                    component={motion.p}
                    variants={item}
                  >
                    Features
                  </Typography>
                </ButtonStyled>

                <ButtonStyled disableRipple variant="text" component={NavLink} to="/#services" size="large">
                  <Typography
                    variant="text"
                    color={navbarFontColor}
                    className="item"
                    variants={item}
                    component={motion.p}
                  >
                    Services
                  </Typography>
                </ButtonStyled>

                <ButtonStyled disableRipple component={NavLink} variant="text" to="/projects" size="large">
                  <Typography
                    variant="text"
                    color={navbarFontColor}
                    className="item"
                    variants={item}
                    component={motion.p}
                  >
                    Our projects
                  </Typography>
                </ButtonStyled>
                <ButtonStyled disableRipple component={NavLink} variant="text" to="/#contact_us" size="large">
                  <Typography
                    variant="text"
                    color={navbarFontColor}
                    className="item"
                    variants={item}
                    component={motion.p}
                  >
                    Contact us
                  </Typography>
                </ButtonStyled>

                <ButtonStyledLogIn
                  disableRipple
                  component={NavLink}
                  to="#"
                  onClick={(e) => {
                    window.location.href = "mailto:support@cargosmd.com";
                    e.preventDefault();
                  }}
                >
                  <Typography
                    color={navbarFontColor}
                    sx={{
                      fontSize: 13,
                      fontFamily: "Raleway",
                      fontWeight: 500,
                      lineHeight: "normal",
                    }}
                    className="item"
                    variants={item}
                    component={motion.p}
                  >
                    Request a Quote
                  </Typography>
                </ButtonStyledLogIn>
              </Stack>
            </Grid>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
