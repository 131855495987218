import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import Navbar from "./Navbar";
import { Link, Navigate, useLocation } from "react-router-dom";
import { styled } from "@mui/system";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Footer from "./Footer";
import { useDispatch, useSelector } from "react-redux";
import { setOpenDrawer } from "../store/generalSlice";
import { projectList } from "../data/generalData";

const Project = () => {
  const dispatch = useDispatch();

  const { openDrawer } = useSelector((state) => state.generalSlice);
  function openAndCloseDrawer() {
    dispatch(setOpenDrawer(!openDrawer));
  }
  const ButtonVisit = styled(Button)(() => ({
    textTransform: "none",
    color: "#000",
    fontFamily: "Raleway",
    textAlign: "center",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 700,
    width: "auto",
    height: "auto",
    mx: "auto",
    "&:hover": {
      backgroundColor: "transparent",
      opacity: 0.7,
    },
  }));

  const { hash } = useLocation();

  const selectedProject = () => {
    let project = "";
    if (hash === "#cargos_tms") {
      project = projectList[0];
    }
    if (hash === "#freight_exchange") {
      project = projectList[1];
    }
    if (hash === "#contdeplata") {
      project = projectList[2];
    }
    if (hash === "#bursatransportinfo") {
      project = projectList[3];
    }
    if (hash === "#artaddict") {
      project = projectList[4];
    }
    return project;
  };

  return selectedProject() !== "" ? (
    <Grid
      container
      item
      sx={{
        position: "relative",
        display: "flex",
        mx: "auto",
        height: "auto",
        bgcolor: hash === "#bursatransportinfo" ? "#E0E0E0" : "auto",
      }}
    >
      {/* Header section */}
      <Grid container item sx={{ backgroundColor: "#FDE8D2" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "auto",
            alignItems: "flex-start",
            maxWidth: "1200px",
            mx: "auto",
            width: "100%",
            pb: { xs: "30px", md: "120px" },
            px: { xs: "30px", sm: "60px" },
          }}
        >
          <Navbar open={openDrawer} setOpen={() => openAndCloseDrawer()} color="#000" />
          <Typography
            component="h4"
            sx={{
              pt: { xs: "30px", sm: "60px", md: "127px" },
              fontFamily: "Raleway",
              textTransform: "uppercase",
              color: "rgba(0, 0, 0, 0.35)",
              textAlign: "center",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 700,
              width: "100%",
            }}
          >
            Project Showcase
          </Typography>
          <Typography
            component="h4"
            sx={{
              pt: "27px",
              fontFamily: "Raleway",
              textTransform: "uppercase",
              color: "rgba(0, 0, 0)",
              textAlign: "center",
              fontSize: { xs: "30px", md: "41px" },
              fontStyle: "normal",
              fontWeight: 600,
              width: "100%",
              lineHeight: { xs: "35px", md: "48px" },
            }}
          >
            {selectedProject()?.title}
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignSelf: "center",
              width: "auto",
              minWidth: "260px",
              height: "60px",
              // bgcolor: "#c5c5c5",
              overflow: "hidden",
              boxSizing: "border-box",
              my: "20px",
              border: "2px solid #383838",
            }}
          >
            <img
              src={selectedProject()?.logo}
              alt=""
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                objectFit: "contain",
                padding: "10px",
              }}
            />
          </Box>

          <Typography
            component="h4"
            sx={{
              pt: "37px",
              fontFamily: "Raleway",
              color: "rgba(0, 0, 0)",
              textAlign: "center",
              fontSize: { xs: "16px", md: "21px" },
              lineHeight: { xs: "25px", md: "29px" },
              fontStyle: "normal",
              fontWeight: 400,
              width: "100%",
              maxWidth: "775px",
              mx: "auto",
            }}
          >
            {selectedProject()?.description}
          </Typography>
          <Box
            sx={{
              alignSelf: "center",
              pt: { xs: "10px", md: "30px" },
              pb: "80px",
            }}
          >
            <ButtonVisit
              disableRipple
              component={Link}
              endIcon={<ArrowRightAltIcon />}
              onClick={(e) => {
                window.open(selectedProject()?.url);
                e.preventDefault();
              }}
            >
              visit website
            </ButtonVisit>
          </Box>
        </Box>
      </Grid>
      {/* image */}
      <Grid
        container
        item
        sx={{
          position: "relative",
          height: "content",

          backgroundSize: "contain",
          backgroundPosition: "50% 50%",
          backgroundRepeat: "no-repeat",
          maxWidth: hash === "#cargos_tms" ? "1600px" : "1200px",
          mx: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "calc(100% -114px)",
            top: { xs: "-80px", md: "-114px" },
            alignItems: "flex-start",
            mx: "auto",
            width: "100%",
            pb: { xs: "30px", md: "0px" },
            mb: { xs: "-150px", md: "-60px" },
            px: { xs: "0", sm: "60px" },
            position: "relative",
            zIndex: 1,
            maxWidth: hash === "#cargos_tms" ? "auto" : "925px",
          }}
        >
          <img
            src={selectedProject()?.main_image}
            alt=""
            style={{
              width: "100%",
              height: "auto",
            }}
          />
          <Box sx={{ alignSelf: "center", pt: "30px", pb: "80px" }}>
            <ButtonVisit
              disableRipple
              component={Link}
              endIcon={<ArrowRightAltIcon />}
              onClick={(e) => {
                window.open(selectedProject()?.url);
                e.preventDefault();
              }}
            >
              visit website
            </ButtonVisit>
          </Box>
        </Box>
      </Grid>
      {/* Footer */}
      <Grid container item>
        <Footer color="#FDE8D2" />
      </Grid>

      <Box
        sx={{
          display: "flex",
          position: "absolute",
          width: "auto",
          height: "auto",
          maxWidth: "600px",
          maxHeight: "500px",
          zIndex: 0,
          top: "35%",
          left: "-5%",
        }}
      ></Box>
    </Grid>
  ) : (
    <Navigate to="*" />
  );
};

export default Project;
