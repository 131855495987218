import React from "react";
import Hero from "./Hero";
import Section1 from "./Section1";
import Features from "./Features";
import OurServices from "./OurServices";
import Values from "./Values";
import AboutUs from "./AboutUs";
import Footer from "./Footer";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const Base = () => {
  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));

      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        marginLeft: "auto",
        marginRight: "auto",
        width: "100%",
      }}
    >
      {/* <MetaDecorator
        description={base.pageDescriptionRo}
        title={base.pageTitle}
        imageUrl={companyLogoBlack}
        imageAlt={base.pageDescriptionRo}
        rel="https://cargos.md"
      /> */}
      <Hero />
      <Section1 id="overview" />
      <Features id="features" />
      <OurServices id="services" />
      <Values />
      <AboutUs id="contact_us" />
      <Footer color="#e4e4e4" />
    </div>
  );
};

export default Base;
