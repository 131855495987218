import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import bg_ellepsis from "../images/background_ellepsis.svg";

const Section1 = ({ id }) => {
  const columnsDescription = [
    {
      id: 1,
      title: "Customizable Solutions",
      description:
        "Adapt our versatile logistics solutions to match your specific requirements, optimizing workflows, cost-efficiency, and precision in every operation, to precisely fit your business.",
      strokeColor: "#FFD600",
    },
    {
      id: 2,
      title: "Advanced load Management",
      description:
        "Optimize your operations with our advanced fleet and load management tools. Efficiently allocate resources, track shipments, and maximize capacity utilization, ensuring timely deliveries and cost-effective logistics at every stage of the supply chain.",
      strokeColor: "#002D74",
    },
    {
      id: 3,
      title: "End-to-End Support",
      description:
        "From inception to delivery, our end-to-end support ensures a seamless logistics experience. Count on us for assistance at every phase, guaranteeing efficiency, reliability, and satisfaction throughout your supply chain journey.",
      strokeColor: "#C86C00",
    },
  ];

  return (
    <>
      <Grid
        id={id}
        container
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "1900px",
          mx: "auto",
          backgroundImage: `url(${bg_ellepsis})`,
          backgroundSize: "cover",
          backgroundPosition: "0% 0%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            maxWidth: "1200px",
            mx: "auto",
            pt: { xs: "30px", sm: "60px", md: "90px" },
            pb: { xs: "30px", sm: "60px", md: "120px" },
            px: { xs: "30px", sm: "60px", md: "60px" },
          }}
        >
          <Typography
            sx={{
              width: { xs: "auto", md: "810px" },
              height: { xs: "auto", md: "110px" },
              mx: "auto",
              mt: { xs: 0, sm: "20px" },
              mb: { xs: "10px", md: "30px" },
              color: "#000",
              textAlign: "center",
              fontFamily: "Raleway",
              fontSize: { xs: "20px", sm: "30px", md: "42px" },
              fontStyle: "normal",
              fontWeight: 600,
              whiteSpace: "pre-line",
              lineHeight: { xs: "28px", sm: "35px", md: "42px" },
            }}
          >
            Welcome to Cargos<span style={{ color: "#e46000" }}>Software</span>,{" "}
            {"\n"}Where Transport Meets Technology
          </Typography>
          <Typography
            sx={{
              height: "auto",
              mx: "auto",
              mt: { xs: "10px", md: "20px" },
              mb: { xs: "20px", md: "100px" },
              textAlign: "center",
              fontFamily: "Raleway",
              fontSize: { xs: "14px", sm: "19px", md: "21px" },
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: { xs: "20px", sm: "28px", md: "32px" },
              color: "rgba(0, 0, 0, 0.70)",
              maxWidth: "1000px",
            }}
          >
            With in-depth knowledge of the shipping and logistics industry
            Cargos Software is one of the leading software and IT companies that
            harness technology, innovation, AI and digital mastery for a big
            range of IT services.
          </Typography>

          <Box
            sx={{
              position: "relative",
              display: "flex",
              flexDirection: { sm: "row", xs: "column" },
              justifyContent: "space-around",

              height: "auto",
            }}
          >
            {columnsDescription.map((column) => (
              <Grid
                key={column.id}
                item
                sm={4}
                xs={12}
                sx={{
                  borderColor: column.strokeColor,
                  pb: { xs: "20px", md: 0 },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    borderLeft: "3px solid",
                    borderColor: column.strokeColor,
                    height: "100%",
                  }}
                >
                  <Box
                    sx={{
                      pl: "5%",
                    }}
                  >
                    <Typography
                      component="h3"
                      sx={{
                        color: "#000",
                        fontFamily: "Oswald",
                        fontSize: { xs: "18px", md: "28px" },
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: { xs: "22px", md: "30px" },
                        pt: { xs: "10px", md: "none" },
                      }}
                    >
                      {column.title}
                    </Typography>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="111"
                      height="2"
                      viewBox="0 0 111 2"
                      fill="none"
                    >
                      <path
                        d="M0.0968018 1H110.404"
                        stroke={column.strokeColor}
                        strokeWidth="2"
                      />
                    </svg>
                    <Typography
                      component="h3"
                      sx={{
                        color: "#000",
                        fontFamily: "Raleway",
                        fontSize: { xs: "13px", sm: "18px", md: "20px" },
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: { xs: "20px", sm: "28px", md: "33px" },
                        mt: { xs: "10px", sm: "20px", md: "38px" },
                        mr: { xs: 0, sm: "10px", md: "80px" },
                      }}
                    >
                      {column.description}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Box>
        </Box>
      </Grid>
    </>
  );
};

export default Section1;
