import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  openDrawer: false,
};

export const generalSlice = createSlice({
  name: "generalSlice",
  initialState: initialState,
  reducers: {
    setOpenDrawer: (state, action) => {
      state.openDrawer = action.payload;
      return state;
    },
  },
});

export const { setOpenDrawer } = generalSlice.actions;

export default generalSlice.reducer;
