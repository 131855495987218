import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import innovation from "../images/innovation.svg";
import integrity from "../images/integrity.svg";
import determination from "../images/determination.svg";
import sustainability from "../images/sustaintibility.svg";

const Values = () => {
  const objValues = [
    {
      id: 1000,
      title: "Innovation",
      content: "New ideas push our business forward.",
      icon: innovation,
    },
    {
      id: 2000,
      title: "Integrity",
      content: "We work inclusively, transparently and with integrity.",
      icon: integrity,
    },
    {
      id: 3000,
      title: "Determination",
      content: "Determination gets things done.",
      icon: determination,
    },
    {
      id: 4000,
      title: "Sustainability",
      content: "Our ongoing commitment to positively impacting the world.",
      icon: sustainability,
    },
  ];

  return (
    <Grid
      container
      item
      sx={{
        display: { xs: "none", sm: "flex" },
        background:
          "linear-gradient(317deg, #FCEDDE 0%, #FFE3C7 44.37%, #FFFCFA 100%)",
        position: "relative",
        flexDirection: "column",
        mx: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "auto",
          maxWidth: "1200px",
          mx: "auto",
          pt: { sm: "60px", md: "90px" },
          pb: { sm: "60px", md: "90px", lg: "120px" },
          px: "60px",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", mx: "auto" }}>
          <Typography
            component="h3"
            sx={{
              fontFamily: "Raleway",
              color: "rgba(0, 0, 0, 0.75)",
              textAlign: "center",
              fontSize: { sm: "30px", md: "35px" },
              lineHeight: { sm: "35px", md: "41px" },
              fontStyle: "normal",
              fontWeight: 700,
              height: "auto",
            }}
          >
            CARGOS<span style={{ color: "#F48400" }}> SOFTWARE</span> VALUES
          </Typography>
          <Typography
            sx={{
              fontFamily: "Raleway",
              color: "rgba(0, 0, 0, 0.60)",
              textAlign: "center",
              pb: { sm: "60px", md: "80px" },
              fontSize: "14px",
              lineHeight: "20px",
              fontStyle: "normal",
              fontWeight: 600,
            }}
          >
            Our values shape who we are today and who we want to be in the
            future.
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            mx: "auto",
            // px: "10%",
            width: "100%",
            justifyContent: "space-around",
          }}
        >
          {objValues.map((value) => (
            <Box
              key={value.id}
              sx={{
                display: "flex",
                flexDirection: "column",
                mx: "auto",
                maxWidth: "240px",
                px: "10px",
              }}
            >
              <img
                src={value.icon}
                alt=""
                style={{ height: "45px", width: "auto" }}
              />
              <Typography
                component="h4"
                sx={{
                  fontFamily: "Raleway",
                  color: "rgba(0, 0, 0, 0.75)",
                  pt: "21px",
                  textAlign: "center",
                  fontSize: { sm: "15px", md: "20px" },
                  lineHeight: { sm: "20px", md: "20px" },
                  fontStyle: "normal",
                  fontWeight: 700,
                  mb: "10px",
                  mt: { sm: "10px", md: "20px" },
                }}
              >
                {value.title}
              </Typography>
              <Typography
                component="h4"
                sx={{
                  fontFamily: "Raleway",
                  color: "#000",
                  textAlign: "center",
                  fontSize: { sm: "12px", md: "18px" },
                  lineHeight: { sm: "17px", md: "24px" },
                  fontStyle: "normal",
                  fontWeight: 400,
                  mb: "10px",
                }}
              >
                {value.content}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            mt: "80px",
            px: 0,
            mx: "auto",
            width: "100%",
            maxWidth: "1000px",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="2"
            viewBox="0 0 1000 2"
            fill="none"
          >
            <path d="M0 1L1000 1" stroke="#F48400" strokeOpacity="0.4" />
          </svg>
        </Box>
        <Box sx={{ mx: "auto" }}>
          <Typography
            component="h5"
            sx={{
              fontFamily: "Raleway",
              color: "rgba(244, 132, 0, 1)",
              textAlign: "center",
              fontSize: { sm: "12px", md: "14px" },
              lineHeight: { sm: "18px", md: "20px" },
              fontStyle: "normal",
              fontWeight: 400,
            }}
          >
            We are always evolving - the world is changing but the spirit remain
            the same.
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};

export default Values;
