import { useLayoutEffect, useMemo, useState } from "react";
import { createTheme, useTheme } from "@mui/material/styles";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import "./index.css";
import * as locales from "@mui/material/locale";
import { CssBaseline, ThemeProvider } from "@mui/material";
import ScrollToTop from "./components/ScrollToTop";
import Header from "./components/Header";
import Base from "./components/Base";
import NotFound from "./components/NotFound";
import TermsAndConditions from "./components/TermsAndConditions";
import Projects from "./components/Projects";
import Project from "./components/Project";

function App() {
  const theme = useTheme();
  const [locale] = useState("enUS");

  const themeWithLocale = useMemo(() => {
    return createTheme(theme, locales[locale], {
      palette: {
        backgroundColor: {
          light: "#F3F3F3",
          medium: "#e4e4e4",
        },
      },
      breakpoints: {
        keys: ["xs", "xs1", "sm", "sm1", "md", "md1", "lg", "xl", "xll", "xxl"],
        values: {
          xs: 0,
          xs1: 480,
          sm: 600,
          sm1: 767,
          md: 900,
          md1: 991,
          lg: 1200,
          xl: 1536,
          xll: 1800,
          xxl: 2100,
        },
      },
      typography: {
        fontFamily: [
          "Raleway",
          "Oswald",
          "-apple-system",
          "BlinkMacSystemFont",
          '"Segoe UI"',
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
      },
    });
  }, [locale, theme]);

  const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      window.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
  };

  return (
    <ThemeProvider theme={themeWithLocale}>
      <BrowserRouter>
        <Wrapper>
          <ScrollToTop />
          <CssBaseline />
          <Header>
            <Routes>
              <Route exact path="/" element={<Base />} />
              <Route exact path="/projects" element={<Projects />} />
              <Route exact path="/projects/:id" element={<Project />} />
              <Route exact path="/terms_and_conditions" element={<TermsAndConditions />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Header>
        </Wrapper>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
