import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import vb from "../images/vb.webp";
import vc from "../images/vc.webp";
import iv from "../images/iv.webp";

const contacs = [
  {
    id: 10000,
    name: "Vadim Botnarenco",
    function: "CEO",
    image: vb,
  },
  {
    id: 20000,
    name: "Irina Ivanova",
    function: "CFO",
    image: iv,
  },
  {
    id: 30000,
    name: "Victor Cuznetov",
    function: "COO",
    image: vc,
  },
];

const AboutUs = ({ id }) => {
  return (
    <Grid
      id={id}
      container
      item
      sx={{
        position: "relative",
        display: "flex",
        mx: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          maxWidth: "1200px",
          mx: "auto",
          pt: { xs: "30px", md: "90px" },
          pb: { xs: "30px", md: "120px" },
          px: { xs: "30px", sm: "60px" },
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Grid
          container
          item
          md={3}
          sx={{
            display: "flex",
            flexDirection: "column",
            pr: { xs: "0", md: "10px" },
          }}
        >
          <Typography
            component="h3"
            sx={{
              color: "#000",
              fontFamily: "Raleway",
              fontSize: "24px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "25px",
              textAlign: { xs: "left", md: "right" },
              mb: "10px",
              opacity: "0.7",
            }}
          >
            About us
          </Typography>
          <Typography
            component="h4"
            sx={{
              color: "rgba(0, 0, 0, 0.80)",
              fontFamily: "Raleway",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: { xs: "15px", sm: "20px" },
              textAlign: { xs: "left", md: "right" },
              maxWidth: "300px",
            }}
          >
            We are a team of experts coming from transport and logistics industry with over 20 years of experience. We
            know how important is to use appropriate instruments to manage the huge workload associated with transport
            industry and we are ready to help and guide you to success.
          </Typography>
        </Grid>
        <Grid
          container
          item
          md={9}
          sx={{
            display: { xs: "none", md: "flex" },
            justifyContent: "space-between",
            width: "100%",
            height: "auto",
          }}
        >
          {contacs.map((contact) => (
            <Grid
              key={contact.id}
              item
              md={4}
              sx={{
                display: "flex",
                flexDirection: "column",
                px: "15px",

                height: "100%",
              }}
            >
              <Box
                sx={{
                  maxWidth: "360px",
                  width: "100%",
                  height: "100%",
                  maxHeight: "420px",
                  bgcolor: "#007aff",
                }}
              >
                <img
                  src={contact.image}
                  alt="contact person"
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              </Box>
              <Typography
                component="h4"
                sx={{
                  pt: "20px",
                  color: "rgba(0, 0, 0,1)",
                  fontFamily: "Raleway",
                  fontSize: { xs: "18px", lg: "20px" },
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "30px",
                }}
              >
                {contact.name}
              </Typography>
              <Typography
                component="h4"
                sx={{
                  color: "#717171",
                  fontFamily: "Raleway",
                  fontSize: { xs: "18px", lg: "20px" },
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "30px",
                }}
              >
                {contact.function}
              </Typography>
              <Box>
                <svg xmlns="http://www.w3.org/2000/svg" width="120" height="2" viewBox="0 0 120 2" fill="none">
                  <path d="M0 1H120" stroke="black" />
                </svg>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Grid>
  );
};

export default AboutUs;
