import { useMediaQuery, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import hero_bg from "../images/hero_bg.webp";
import Navbar from "./Navbar";
import { setOpenDrawer } from "../store/generalSlice";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";

const Hero = () => {
  const { openDrawer } = useSelector((state) => state.generalSlice);
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();

  function openAndCloseDrawer() {
    dispatch(setOpenDrawer(!openDrawer));
  }

  const variantText = {
    hidden: { y: -200, opacity: 0 },
    visible: {
      transition: { delay: 1, duration: 2, type: "spring" },
      opacity: 1,
      y: 0,
    },
  };
  const variantSubText = {
    hidden: { opacity: 0 },
    visible: {
      transition: { delay: 4, duration: 2 },
      opacity: 1,
    },
  };
  return (
    <Grid
      container
      item
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundImage: `url(${hero_bg})`,
        backgroundSize: "cover",
        backgroundPosition: "30% 50%",
        zIndex: 1,
        height: "auto",
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "1200px",
          mx: "auto",
          px: { xs: "30px", md: "60px" },
          pb: { xs: "60px", md: "120px" },
        }}
      >
        <Navbar open={openDrawer} setOpen={() => openAndCloseDrawer()} color="#fff" />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            position: "relative",
            maxWidth: "1200px",
            height: "106px",
            marginTop: { xs: "140px", md: "230px" },
            marginRight: "auto",
            marginLeft: "auto",
          }}
        >
          <Typography
            component={motion.h1}
            sx={{
              background:
                "linear-gradient(98deg, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.90) 48.39%, rgba(255, 255, 255, 0.40) 100%)",
              backgroundClip: "text",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              textAlign: { xs: "left", sm: "center" },
              fontFamily: "Raleway",
              fontSize: { xs: "30px", sm: "30px", md: "41px" },
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: { xs: "32px", sm: "35px", md: "53px" },
              textTransform: "uppercase",
              whiteSpace: "pre-line",
              maxWidth: { xs: "280px", sm: "100%" },
            }}
            variants={variantText}
            initial="hidden"
            animate="visible"
          >
            Efficient and Cost-Effective Logistics {!xs && "\n"} Software and IT Services
          </Typography>
          <Typography
            component={motion.h4}
            sx={{
              mt: "17px",
              textAlign: { xs: "left", sm: "center" },
              fontSize: { xs: "15px", md: "15px" },
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: { xs: "15px", md: "20px" },
              textTransform: "uppercase",
              fontFamily: "Raleway",
              background:
                "linear-gradient(90deg, rgba(244, 132, 0, 0.44) 0%, rgba(244, 132, 0, 0.80) 44.79%, rgba(244, 132, 0, 0.44) 100%)",
              backgroundClip: "text",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              maxWidth: { xs: "250px", sm: "100%" },
            }}
            variants={variantSubText}
            initial="hidden"
            animate="visible"
          >
            Streamline your operations with our comprehensive solutions
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            maxWidth: "1200px",
            ml: "auto",
            mr: "auto",
            width: "100%",
            mt: { xs: "350px", md: "400px" },
            mb: { xs: "20px", md: 0 },
          }}
        >
          <Typography
            component={motion.h4}
            sx={{
              color: "rgba(255, 255, 255, 0.70)",
              fontFamily: "Raleway",
              fontSize: { xs: "12px", md: "15px" },
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
            }}
            variants={variantSubText}
            initial="hidden"
            animate="visible"
          >
            Cargos Software can become your guide & key to success.
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};

export default Hero;
