import React from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Box } from "@mui/material";

const SocialMedia = ({ color, sx }) => {
  return (
    <Box sx={{ display: "flex", gap: "15px", ...sx }}>
      <FacebookIcon
        onClick={() =>
          window.open("https://www.facebook.com/profile.php?id=100086948343051")
        }
        sx={{
          height: "30px",
          width: "auto",
          color: "#494949",
          cursor: "pointer",
          "&:hover": {
            opacity: 0.8,
            transform: "scale(1.1)",
            color: "#0866ff",
          },
        }}
      />
      <InstagramIcon
        onClick={() =>
          window.open(
            "https://www.instagram.com/cargos_software?fbclid=IwAR2EZEmVL7jEIAYMnEJaABzbYPolbBhslzqdSHk_fRgeoww9CUA9tNsBrsI"
          )
        }
        sx={{
          height: "30px",
          width: "auto",
          color: "#494949",
          cursor: "pointer",
          "&:hover": {
            opacity: 0.8,
            // transform: "scale(1.1)",
            color: "#fff",
            background:
              "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)",
            borderRadius: "8px",
          },
        }}
      />
      <LinkedInIcon
        onClick={() =>
          window.open(
            "https://www.linkedin.com/company/91466500/admin/feed/posts/"
          )
        }
        sx={{
          height: "30px",
          width: "auto",

          color: "#494949",
          cursor: "pointer",
          "&:hover": {
            opacity: 0.8,
            transform: "scale(1.1)",
            color: "#0a66c2",
          },
        }}
      />
    </Box>
  );
};

export default SocialMedia;
