import { useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import ourServices from "../images/our_services.webp";
import web_design from "../images/web-designer.webp";
import airport from "../images/airport.webp";

const OurServices = ({ id }) => {
  const mdMax600 = useMediaQuery("(max-width:600px)");

  const ServicesData = [
    {
      id: 100,
      title: "Freight Management software",
      cardDescription:
        "Our Freight Management software transforms cargo handling with excellent efficiency. Combining latest technology and industry expertise, it optimizes the entire logistics process.",
      description:
        "From initial consignment to final delivery, our solution streamlines routes, ensures timely pickups, tracks shipments, and manages documentation seamlessly. With a focus on precision and reliability, it handles diverse cargo types, sizes, and destinations. Our software navigates complexities, reducing transit times, minimizing costs, and offering transparent communication. Enjoy peace of mind while efficiently managing your freight, regardless of volume or specific requirements, as our software provides comprehensive solutions tailored to elevate your logistics operations.",
      image: airport,
    },
    {
      id: 200,
      title: "Supply chain it services",
      cardDescription:
        "Our Supply Chain IT Optimization service drives streamlined logistics by integrating innovative technology solutions into every facet of your supply chain.",
      description:
        "We meticulously analyze, strategize, and implement IT systems tailored to your operations, ensuring seamless coordination, and enhanced efficiency across all processes. From inventory management to distribution, our comprehensive approach optimizes workflows, reduces bottlenecks, and enhances agility. With a focus on seamless integration and performance enhancement, we empower your supply chain with adaptable solutions that elevate your logistics, improve decision-making, and drive sustainable growth in today's dynamic business landscape.",
      image: ourServices,
    },
    {
      id: 300,
      title: "Branding & Web Development",
      cardDescription:
        "Our Branding & Web Development  is a specialized service package tailored for transport and logistics companies, and other industries on demand.",
      description:
        "We specialize in crafting impactful brand identities, encompassing logo design, web design, and comprehensive web page development. Our expertise extends from conceptualization to execution, ensuring visually captivating logos, intuitive website designs, and a cohesive brand identity that resonates with your audience. With a focus on industry-specific needs, we establish a robust online presence aligned with your business goals, enhancing credibility and fostering meaningful customer connections.",
      image: web_design,
    },
  ];
  const [item, setItem] = useState(!mdMax600 ? 0 : undefined);

  function openSelected(index) {
    if (mdMax600) {
      setItem(undefined);
    } else setItem(index);
  }

  useEffect(() => {}, [item]);

  return (
    <Grid
      id={id}
      container
      item
      sx={{
        display: "flex",
        flexDirection: "column",
        mx: "auto",
        pb: { xs: "0px", sm: "60px", md: "190px" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "auto",
          maxWidth: "1200px",
          mx: "auto",
          pt: { xs: "0", sm: "40px", md: "60px", lg: "120px" },
          px: { xs: "30px", sm: "60px", md: "60px" },
        }}
      >
        <Grid
          container
          item
          sx={{
            display: "flex",

            alignItems: "start",
            height: "auto",
          }}
        >
          <Grid
            item
            md={3}
            sm={12}
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                display: { xs: "flex" },
                fontFamily: "Raleway",
                color: "#000",
                fontSize: { xs: "30px", md: "41px" },
                lineHeight: { xs: "30px", md: "40px" },
                fontStyle: "normal",
                fontWeight: 600,
                height: "auto",
                textTransform: "uppercase",

                alignSelf: { xs: "left", sm: "center", md: "flex-start" },
                mt: "20px",
                mb: "10px",
                mr: 0,
                maxWidth: { xs: "auto", md: "200px" },
              }}
            >
              OUR SERVICES
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={9}
            sx={{
              display: "flex",
              height: { xs: "auto", sm: "auto" },
              gap: { xs: "20px", sm: "inherit" },
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            {ServicesData.map((service, index) => (
              <Grid
                item
                md={3.9}
                sm={3.9}
                xs={12}
                key={service.id}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "auto",
                  boxShadow: mdMax600
                    ? "0px 4px 50px 0px rgba(0, 0, 0, 0.2)"
                    : index === item
                    ? "0px 4px 50px 0px rgba(0, 0, 0, 0.2)"
                    : "none",
                  backgroundColor: mdMax600 ? "#fff" : "none",
                  "&:hover": {
                    backgroundColor: !mdMax600 && index !== item ? "#fff4e6" : !mdMax600 ? "#fff" : "none",
                    cursor: "pointer",
                  },
                }}
                onClick={() => openSelected(index)}
              >
                <CardContent
                  sx={{
                    pt: { xs: "10px", md: "20px" },
                    pb: { xs: "10px", md: "10px" },
                    px: { xs: "8px", md: "10px" },
                    backgroundColor: mdMax600 ? "#fff" : "auto",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Oswald",
                      fontSize: "19px",
                      lineHeight: "25l",
                      fontStyle: "normal",
                      fontWeight: 500,
                      height: "auto",
                      textTransform: "uppercase",
                      textAlign: "start",
                      borderTop: mdMax600
                        ? "2px solid #F48400"
                        : item === index
                        ? "2px solid #F48400"
                        : "2px solid #FFF",
                    }}
                  >
                    {service.title}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Raleway",
                      fontSize: "14px",
                      lineHeight: "20px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      pt: "16px",
                      textAlign: "start",
                      height: "auto",
                    }}
                  >
                    {service.cardDescription}
                  </Typography>
                </CardContent>
                {mdMax600 && (
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src={service.image}
                      alt={service.description}
                      style={{
                        objectFit: "contain",
                        width: "100%",
                        height: "auto",
                      }}
                    />
                  </Box>
                )}
              </Grid>
            ))}
          </Grid>
        </Grid>
        {/* images */}
      </Box>
      <Box
        sx={{
          position: "relative",
          mx: "auto",
          width: "auto",
          mt: "30px",
        }}
      >
        {!mdMax600 &&
          ServicesData.map(
            (service, index) =>
              index === item && (
                <Grid
                  key={service.id}
                  container
                  item
                  sx={{
                    mt: "60px",
                    display: "flex",
                    flexDirection: "row",
                    width: "auto",
                    maxWidth: "1200px",
                    height: "auto",
                    mr: { xs: 0 },
                    flexWrap: "inherit",
                    px: { sm: "60px" },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      width: "auto",
                    }}
                  >
                    <img
                      src={service.image}
                      alt=""
                      style={{
                        width: "100%",
                        objectFit: "contain",
                        height: "100%",
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      minWidth: "300px",
                      maxWidth: "400px",
                      backgroundColor: "#FFF",
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        alignSelf: "center",
                        color: "#000",
                        fontFamily: "Raleway",
                        fontSize: { sm: "13px", md: "14px" },
                        lineHeight: { sm: "18px", md: "20px" },
                        fontStyle: "normal",
                        fontWeight: 400,
                        px: { sm: "20px", md: "20px" },
                        pb: { sm: "10px", md: "20px" },
                        pt: { sm: "10px", md: "20px" },
                        textAlign: "left",
                        height: "100%",
                      }}
                    >
                      {service.description}
                    </Typography>
                  </Box>
                </Grid>
              )
          )}
      </Box>
    </Grid>
  );
};

export default OurServices;
