import { Helmet } from "react-helmet-async";
import { PropTypes } from "prop-types";

const MetaDecorator = ({ title, description, imageUrl, imageAlt }) => {
  return (
    <Helmet>
      <title>{title}</title>
      {/* <meta name="title" content={title} />
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:image" content={imageUrl} />
      <meta
        property="og:url"
        content={window.location.pathname + window.location.search}
      />
      <meta property="og:image:alt" content={imageAlt} /> */}
    </Helmet>
  );
};

MetaDecorator.propTypes = {
  title: PropTypes.string.isRequired,
  // description: PropTypes.string.isRequired,
  // imageUrl: PropTypes.string.isRequired,
  // imageAlt: PropTypes.string.isRequired,
};

export default MetaDecorator;
