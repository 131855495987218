import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Navbar from "./Navbar";
import { setOpenDrawer } from "../store/generalSlice";
import MetaDecorator from "./MetaDecorator";

const NotFound = () => {
  const dispatch = useDispatch();

  const { openDrawer } = useSelector((state) => state.generalSlice);
  function openAndCloseDrawer() {
    dispatch(setOpenDrawer(!openDrawer));
  }

  return (
    <>
      <MetaDecorator title="Page not found! Error 404" />
      <Grid
        container
        item
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          mx: "auto",
          height: "100vh",

          background: "linear-gradient(288deg, #FCEDDE 5.46%, #FFE3C7 48.06%, #FFFCFA 101.46%)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "auto",
            alignItems: "flex-start",
            maxWidth: "1200px",
            mx: "auto",
            width: "100%",
            pb: { xs: "30px", md: "120px" },
            px: { xs: "30px", md: "60px" },
          }}
        >
          <Navbar color="#000" open={openDrawer} setOpen={() => openAndCloseDrawer()} />
          <Typography
            component="h2"
            sx={{
              pt: { xs: "25vh", sm: "25vh", md: "30vh" },
              fontFamily: "Roboto",
              color: "rgba(0, 0, 0)",
              textAlign: "center",
              fontSize: "72px",
              fontStyle: "normal",
              fontWeight: 700,
              width: "100%",
            }}
          >
            404
          </Typography>
          <Typography
            component="h2"
            sx={{
              fontFamily: "Roboto",

              color: "rgba(0, 0, 0)",
              textAlign: "center",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 700,
              width: "100%",
            }}
          >
            Sorry, we were unable to find that page
          </Typography>
          <Typography
            component="h2"
            sx={{
              fontFamily: "Roboto",

              color: "rgba(0, 0, 0)",
              textAlign: "center",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 700,
              width: "100%",
            }}
          >
            Start from{" "}
            <span>
              <Link to="/">home</Link>
            </span>{" "}
            page
          </Typography>
        </Box>
      </Grid>
    </>
  );
};

export default NotFound;
